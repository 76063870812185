<template>
  <div>
    <AppBar title="Zerotier" :user="user" />
    <v-container>
      <v-data-iterator
        :items="members"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar dark class="mb-1">
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-select
              v-model="selectedNetwork"
              flat
              solo-inverted
              hide-details
              :items="networks"
              prepend-inner-icon="mdi-wan"
              label="Network"
            ></v-select>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-btn small dark @click="callZerotierMemberFunction">
                <v-icon left>mdi-cached</v-icon>sync
              </v-btn>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card dark>
                <v-card-subtitle
                  ><v-badge
                    dot
                    overlap
                    left
                    :color="item.online ? 'green' : 'red'"
                  >
                    <v-icon left> mdi-desktop-classic </v-icon> </v-badge
                  >{{ item.name }}</v-card-subtitle
                >
                <v-container>
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            pill
                            small
                            :color="
                              item.clientVersion < `1.8.0` ? `red` : `green`
                            "
                            outlined
                          >
                            v{{ item.clientVersion }}
                          </v-chip>
                        </template>
                        <span>NodeID: {{ item.nodeId }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-btn
                      small
                      :disabled="!item.online"
                      outlined
                      rounded
                      color="success"
                      @click="connect(item.config.ipAssignments[0])"
                      >Connect
                    </v-btn>
                    <v-overlay :value="overlay" opacity="0.5">
                      {{ overlayText }}
                      <v-progress-circular
                        indeterminate
                        size="30"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-if="members != []" v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="black"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn fab small dark class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab small dark class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import { functions } from "../firebase";

export default {
  props: ["user"],
  data() {
    return {
      itemsPerPageArray: [100, 200, 500],
      search: "",
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 100,
      sortBy: "online",
      overlay: false,
      overlayText: "",
      members: [],
      selectedNetwork: "9f77fc393e381b2e",
      networks: [
        { text: "SuperNet", value: "d5e5fb653797c558" },
        { text: "LeftBrain Support Network", value: "9f77fc393e381b2e" },
      ],
    };
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.members.length / this.itemsPerPage);
    },
  },
  components: {
    AppBar,
  },
  methods: {
    connect(ip) {
      this.overlayText = "Initiating Connection Protocols...";
      this.overlay = !this.overlay;
      window.open(`vnc://${ip}`);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    callZerotierMemberFunction() {
      this.overlayText = "Downloading Network Members...";
      this.overlay = !this.overlay;
      this.members = [];
      functions()
        .httpsCallable("zerotierMembers")({ networkId: this.selectedNetwork })
        .then((result) => {
          this.members = result.data.members;
          // console.dir(result.data.members);
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    },
  },
};
</script>