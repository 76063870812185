

<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col>
        <v-card class="pa-7" flat>
          <v-col cols="1" bm="1">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey lighten-1">mdi-help-circle-outline</v-icon>
              </template>
              <span>
                <b>Client Status</b>
                <br />This gives the client a general idea of the results as we see them, your choice will appear on the final output page.
                <br />
                <b>Summary Text</b>
                <br />A space for you to sumarise anything that could be useful for the client; updates for ongoing issues, process changes, etc.
                <br />
                <b>Support Summary - Common Issue</b>
                <br />Here you can enter a brief list of ongoing issues, consider keeping this as brief as possible.
                <br />
                <b>Infrastructure Summary</b>
                <br />Here you can manually list hardware devices managed by Infrastructure such as servers, backup media, etc.
                <br />
                <b>Active Subscriptions</b>
                <br />Here you can manually list active subscriptions the client has.
              </span>
            </v-tooltip>
          </v-col>
          <v-select
            :items="statusSelect"
            v-model="clientStatus"
            label="Client Status"
            clearable
            prepend-inner-icon="mdi-calendar-check"
            outlined
          ></v-select>

          <v-row align="end" justify="start">
            <v-col sm="8" offset-md="1">
              <v-textarea
                outlined
                :disabled="summaryDisabled"
                auto-grow
                label="Enter Summary text here."
                v-model="summaryText"
              ></v-textarea>
            </v-col>
            <v-col>
              <v-row align="start" justify="start">
                <v-btn
                  class="mx-2"
                  small
                  dark
                  color="primary"
                  @click="saveSummaryText(summary)"
                >SAVE</v-btn>
                <v-btn class="mx-2" small dark color="secondary" @click="summaryEdit()">EDIT</v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-card flat class="pa-1">
            <h3>Support Summary</h3>
            <p>Average Response Time: {{ replyTimeString }}</p>
            <p>Average Time to Solution: {{ averageConversationLength }}</p>
            <p>Total Conversations: {{ conversationCount }}</p>
            <p>Top Support Issues:</p>
            <ul>
              <li v-for="item in supportIssues" :key="item.title">{{ item.title }}</li>
            </ul>
            <v-card flat>
              <v-row align="end" justify="start">
                <v-col sm="8" offset-md="1">
                  <v-text-field outlined label="Add Common Issue" v-model="issue.title"></v-text-field>
                </v-col>
                <v-col>
                  <v-row align="start" justify="start">
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      dark
                      color="primary"
                      @click="addSupportIssue(issue)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      fab
                      small
                      dark
                      color="secondary"
                      @click="removeSupportIssue()"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          <br />

          <h3>Infrastructure Summary</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Server</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="server in infraSummary" :key="server.server">
                  <td>{{ server.name }}</td>
                  <td>{{ server.status }}</td>
                  <td>{{ server.notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card flat>
            <v-row align="end" justify="start">
              <v-col sm="8" offset-md="1">
                <v-text-field outlined label="Server Name" v-model="server.name"></v-text-field>
                <v-text-field outlined label="Status" v-model="server.status"></v-text-field>
                <v-text-field outlined label="Notes" v-model="server.notes"></v-text-field>
              </v-col>
              <v-col>
                <v-row align="start" justify="start">
                  <v-btn class="mx-2" fab small dark color="primary" @click="addServer(server)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" fab small dark color="secondary" @click="removeAddedServer()">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <h3>Active Subscriptions</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Service</th>
                  <th class="text-left">User Count</th>
                  <th class="text-left">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subscription in clientSubscriptionsData" :key="subscription.title">
                  <td>{{ subscription.title }}</td>
                  <td>{{ subscription.userCount }}</td>
                  <td>{{ subscription.notes }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card flat>
            <v-row align="end" justify="start">
              <v-col sm="8" offset-md="1">
                <v-text-field outlined label="Service" v-model="subscription.title"></v-text-field>
                <v-text-field outlined label="User Count" v-model="subscription.userCount"></v-text-field>
                <v-text-field outlined label="Notes" v-model="subscription.notes"></v-text-field>
              </v-col>
              <v-col>
                <v-row align="start" justify="start">
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="primary"
                    @click="addSubscription(subscription)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    dark
                    color="secondary"
                    @click="removeAddedSubscripion()"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>




<script>
import moment from "moment";
export default {
  props: [
    "replyTimeString",
    "domain",
    "conversationCount",
    "averageConversationLength",
    "quarter",
    "year",
    "statusSelect",
    "infraSummary",
    "supportIssues",
    "clientSubscriptionsData",
    "summary"
  ],
  data() {
    return {
      clientStatus: null,
      summaryDisabled: false,
      issue: {
        title: null
      },
      summaryText: null,
      server: {
        name: "",
        status: "",
        notes: ""
      },
      subscription: {
        title: "",
        userCount: "",
        notes: ""
      }
    };
  },
  computed: {},
  methods: {
    addServer() {
      this.infraSummary.push(this.server);
      this.server = {
        name: "",
        status: "",
        notes: ""
      };
    },
    removeAddedServer() {
      this.infraSummary.pop();
    },
    saveSummaryText() {
      this.summary.pop();
      this.summary.push(this.summaryText);
      this.summaryDisabled = true;
    },
    summaryEdit() {
      this.summaryDisabled = false;
    },
    addSubscription() {
      this.clientSubscriptionsData.push(this.subscription);
      this.subscription = {
        title: "",
        userCount: "",
        notes: ""
      };
    },
    removeAddedSubscripion() {
      this.clientSubscriptionsData.pop();
    },
    addSupportIssue() {
      this.supportIssues.push(this.issue);
      this.issue = {
        title: ""
      };
    },
    removeSupportIssue() {
      this.supportIssues.pop();
    }
  }
};
</script>