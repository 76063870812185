

<template>
  <v-container style="padding-left=20px" fluid>
    <v-row no-gutters justify="start" align="center">
      <v-card flat class="pa-2" tile color="blue lighten-3">
        <v-card flat class="pa-1">
          <h1>Report for {{ clientName }} {{ quarter }} {{ year }}</h1>
          <h3>Support Summary</h3>
          <p>Average Response Time: {{ replyTimeString }}</p>
          <p>Average Time to Solution: {{ averageConversationLength }}</p>
          <p>Total Conversations: {{ conversationCount }}</p>
          <p>Top Support Issues:</p>
          <ul>
            <li v-for="item in supportIssues" :key="item.title">{{ item.title }}</li>
          </ul>
          <br/>
          <p>{{ summary[0] }}</p>
        </v-card>
        <br />
        <h3>Infrastructure Summary</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Server</th>
                <th class="text-left">Status</th>
                <th class="text-left">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(server, index) in infraSummary" :key="server.name+index">
                <td>{{ server.name }}</td>
                <td>{{ server.status }}</td>
                <td>{{ server.notes }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3>Active Subscriptions</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Service</th>
                <th class="text-left">User Count</th>
                <th class="text-left">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subscription in clientSubscriptionsData" :key="subscription.title">
                <td>{{ subscription.title }}</td>
                <td>{{ subscription.userCount }}</td>
                <td>{{ subscription.notes }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-row>
  </v-container>
</template>




<script>
import moment from "moment";
// import ReportServersEditor from "../components/ReportServersEditor.vue";
// import SupportSummaryEditor from "../components/SupportSummaryEditor.vue";

export default {
  props: [
    "replyTimeString",
    "clientName",
    "domain",
    "conversationCount",
    "averageConversationLength",
    "quarter",
    "year",
    "infraSummary",
    "supportIssues",
    "clientSubscriptionsData",
    "summary"
  ],
  data() {
    return {};
  },
  computed: {},
  components: {
    // ReportServersEditor,
    // SupportSummaryEditor
  },
  methods: {}
};
</script>