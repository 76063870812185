<template>
  <div>
    <AppBar class="d-print-none" title="Intercom Client Stats" :user="user" />
    <v-row class="mb-6" justify="center" align="start">
      <v-col cols="2" sm="4" md="6" class="d-print-none">
        <br />
        <v-card class="pa-7" raised>
          <v-form>
            <v-text-field outlined clearable label="Client Name" v-model="clientName"></v-text-field>

            <v-text-field
              v-model="domain"
              label="Domain"
              outlined
              clearable
              :rules="[() => !!domain || 'This field is required']"
              required
            ></v-text-field>
            <v-row justify="center" align="center">
              <v-col cols="0" md="16">
                <v-radio-group row v-model="searchMode">
                  <v-radio label="Manual Input" value="manual"></v-radio>
                  <v-radio label="Yearly Quarters" value="quarters"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-col v-if="this.searchMode === 'manual'">
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-x
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar-check"
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" @input="startMenu = false"></v-date-picker>
              </v-menu>

              <v-menu
                ref="endMenu"
                v-model="endMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-x
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDate"
                    label="End Date"
                    prepend-inner-icon="mdi-calendar-check"
                    readonly
                    outlined
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @input="endMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-row justify="center" align="center">
              <v-col cols="1" md="3" v-if="this.searchMode === 'quarters'">
                <v-radio-group v-model="quarter">
                  <v-radio label="Q1" value="Q1">{{ dateModeSelection() }}</v-radio>
                  <v-radio label="Q2" value="Q2"></v-radio>
                  <v-radio label="Q3" value="Q3"></v-radio>
                  <v-radio label="Q4" value="Q4"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="2" sm="5" md="5" v-if="this.searchMode === 'quarters'">
                <v-select
                  :items="years"
                  v-model="year"
                  label="Year"
                  prepend-inner-icon="mdi-calendar-check"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="1" sm="2">
                <v-btn color="primary" @click="callIntercomSearchFunction">
                  Search
                  <v-icon right>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" sm="3">
                <v-btn @click="resetForm()">
                  <v-icon>mdi-refresh</v-icon>Reset Form
                </v-btn>
              </v-col>
              <v-col v-show="hasLoaded" cols="1" sm="2">
                <v-btn color="primary" @click="print()">
                  <v-icon>mdi-printer</v-icon>Print
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="grey lighten-1">mdi-help-circle-outline</v-icon>
              </template>
              <span>
                <b>Client Name</b>
                <br />This is case sensitive and will appear as typed on the final output page.
                <br />
                <b>Domain</b>
                <br />This is requried. The domain is the key element that is used to search for the client's conversation data.
                <br />
                <b>Manual Input</b>
                <br />Selecting this will allow you to enter a custom start and end date for the conversation search.
                <br />
                <b>Yearly Quarters</b>
                <br />Default setting. Selecting this will allow you choose a specfic date quarter and year from the options displayed.
              </span>
            </v-tooltip>
          </v-row>
        </v-card>
        <br />
        <v-card v-show="hasLoaded" raised>
          <v-col>
            <h2>Intercom Conversations {{ formatDate(startDate) }} to {{ formatDate(endDate) }}</h2>
            <br />
            <v-data-table
              :headers="headers"
              :items="functionResult.intercomConversations"
              class="elevation-1"
            >
              <template v-slot:item.source.subject="{ item }">
                <div
                  style="padding:0 !important"
                  v-if="item.source.subject"
                  v-html="item.source.subject.slice(0, 74)"
                />
                <div
                  v-else-if="item.source.body"
                  v-html="item.source.body.slice(0, 74)"
                  style="max-height:22px;overflow-y:hidden"
                ></div>
              </template>
              <template v-slot:item.statistics.time_to_admin_reply="{ item }">
                <v-chip
                  :color="getColor(item.statistics.time_to_admin_reply)"
                  dark
                >{{ niceMomentTime(item.statistics.time_to_admin_reply) }}</v-chip>
              </template>
              <template v-slot:item.statistics.time_to_first_close="{ item }">
                <v-chip
                  color="blue"
                  dark
                >{{ niceMomentDaysAndTime(item.statistics.time_to_first_close) }}</v-chip>
              </template>
              <template v-slot:item.conversation_rating.rating="{ item }">
                <span
                  v-if="item && item.conversation_rating"
                >{{ratingToEmoji( item.conversation_rating.rating)}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="openIntercomConversation(item.id)">
                  <v-icon small class>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-col>
      <v-col class="d-print-none" cols="2" sm="4" md="4">
        <!-- <ReportServersEditor v-show="hasLoaded" v-model="servers" />
        <SupportSummaryEditor v-show="hasLoaded" v-model="supportIssues" />-->
        <IntercomStatsManualData
          v-show="hasLoaded"
          :replyTimeString="formatTimeForPrint(averageReplyTime)"
          :domain="domain"
          :startDate="startDate"
          :endDate="endDate"
          :averageConversationLength="formatTimeForPrint(averageConversationLength)"
          :conversationCount="conversationCount"
          :quarter="quarter"
          :year="year"
          :statusSelect="statusSelect"
          :supportIssues="supportIssues"
          :infraSummary="infraSummary"
          :clientSubscriptionsData="clientSubscriptionsData"
          :summary="summary"
        />
      </v-col>
    </v-row>
    <v-row class="mb-6" justify="center" align="start">
      <!-- Print View section -->
      <IntercomPrintView
        class="d-none d-print-block"
        v-if="true"
        :replyTimeString="formatTimeForPrint(averageReplyTime)"
        :domain="domain"
        :startDate="startDate"
        :endDate="endDate"
        :averageConversationLength="formatTimeForPrint(averageConversationLength)"
        :conversationCount="conversationCount"
        :quarter="quarter"
        :year="year"
        :clientName="clientName"
        :servers="servers"
        :supportIssues="supportIssues"
        :infraSummary="infraSummary"
        :clientSubscriptionsData="clientSubscriptionsData"
        :summary="summary"
      />
    </v-row>
    <v-overlay class="d-print-none" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import { functions } from "../firebase";
import moment from "moment";
import IntercomStatsManualData from "../components/IntercomStatsManualData.vue";
import IntercomPrintView from "../components/IntercomPrintView.vue";
// import ReportServersEditor from "../components/ReportServersEditor.vue";
// import SupportSummaryEditor from "../components/SupportSummaryEditor.vue";
export default {
  props: ["user"],
  data() {
    return {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      domain: "",
      clientName: "",
      summary: [],
      servers: [],
      supportIssues: [],
      infraSummary: [],
      clientSubscriptionsData: [],
      statusSelect: ["Awesome! 🤓", "Meets Standards", "Needs Improvement"],
      isLoading: false,
      startMenu: false,
      endMenu: false,
      hasLoaded: false,
      unixdate: null,
      viewMainSearchWindow: true,
      overlay: false,
      functionResult: { intercomConversations: [] },
      viewMessageText: false,
      conversationCount: null,
      year: new Date().toISOString().substr(0, 4),
      years: ["2019", "2020", "2021", "2022", "2023"],
      quarter: "Q1",
      searchMode: "quarters",
      headers: [
        { text: "ID", value: "id" },
        { text: "Subject", value: "source.subject" },
        { text: "Reply Time (h:m:s)", value: "statistics.time_to_admin_reply" },
        {
          text: "Time to close (d:h:m:s)",
          value: "statistics.time_to_first_close"
        },
        { text: "Rating", value: "conversation_rating.rating" },
        { text: "Remark", value: "conversation_rating.remark" },
        { text: "Actions", value: "actions" }
      ]
    };
  },
  computed: {
    unixStartDate() {
      return Number(new Date(this.startDate)) / 1000;
    },
    unixEndDate() {
      return Number(new Date(this.endDate)) / 1000;
    },
    averageReplyTime() {
      if (
        !this.functionResult ||
        !this.functionResult.intercomConversations ||
        !this.functionResult.intercomConversations.length
      )
        return 0;
      const conversations = this.functionResult.intercomConversations;
      const replyTimes = conversations
        .filter(conversation => conversation.statistics.time_to_admin_reply)
        .map(conversation => {
          return conversation.statistics.time_to_admin_reply;
        });
      return replyTimes.reduce((a, b) => a + b) / replyTimes.length;
    },
    averageConversationLength() {
      if (
        !this.functionResult ||
        !this.functionResult.intercomConversations ||
        !this.functionResult.intercomConversations.length
      )
        return 0;
      const conversations = this.functionResult.intercomConversations;
      const conversationLength = conversations
        .filter(conversation => conversation.statistics.time_to_first_close)
        .map(conversation => {
          return conversation.statistics.time_to_first_close;
        });
      return (
        conversationLength.reduce((a, b) => a + b) / conversationLength.length
      );
    }
  },
  methods: {
    getColor(time_to_admin_reply) {
      if (time_to_admin_reply === null) return "grey";
      else if (time_to_admin_reply > 3600) return "red";
      else if (time_to_admin_reply > 1200) return "orange";
      else return "green";
    },
    convertUnixTime(time_to_admin_reply, time_to_first_close) {
      return moment(time_to_admin_reply, time_to_first_close).format(
        "hh:mm:ss"
      );
    },
    openIntercomConversation(id) {
      window.open(
        "https://app.intercom.com/a/apps/oged1suc/inbox/inbox/all/conversations/" +
          id,
        "_blank"
      );
    },
    print() {
      window.print();
    },
    formatDate(dateValue) {
      return moment(dateValue).format("DD/MM/YYYY");
    },
    dateModeSelection() {
      if (this.quarter === "Q1")
        (this.startDate = new Date("January 1," + this.year)
          .toISOString()
          .substr(0, 10)),
          (this.endDate = new Date("April 1," + this.year)
            .toISOString()
            .substr(0, 10));
      else if (this.quarter === "Q2")
        (this.startDate = new Date("April 1," + this.year)
          .toISOString()
          .substr(0, 10)),
          (this.endDate = new Date("July 1," + this.year)
            .toISOString()
            .substr(0, 10));
      else if (this.quarter === "Q3")
        (this.startDate = new Date("July 1," + this.year)
          .toISOString()
          .substr(0, 10)),
          (this.endDate = new Date("October 1," + this.year)
            .toISOString()
            .substr(0, 10));
      else if (this.quarter === "Q4")
        (this.startDate = new Date("October 1," + this.year)
          .toISOString()
          .substr(0, 10)),
          (this.endDate = new Date("December 31," + this.year)
            .toISOString()
            .substr(0, 10));
    },
    ratingToEmoji(rating) {
      switch (rating) {
        case 1:
          return "😠";
        case 2:
          return "🙁";
        case 3:
          return "😐";
        case 4:
          return "😃";
        case 5:
          return "🤩";
        default:
          return "";
      }
    },
    callIntercomSearchFunction() {
      this.overlay = !this.overlay;
      this.hasLoaded = true;
      functions()
        .httpsCallable("intercomStats")({
          domain: this.domain,
          startDate: this.unixStartDate,
          endDate: this.unixEndDate
        })
        .then(result => {
          this.functionResult = result.data;
          this.conversationCount = this.functionResult.intercomConversations.length;
          console.log(this.functionResult);
        })
        .catch(error => {
          alert(error);
          console.log(error);
          console.log(this.functionResult);
        });
    },
    resetForm() {
      this.domain = null;
      this.viewMainSearchWindow = true;
      this.hasLoaded = false;
      this.functionResult = { intercomConversations: [] };
    },
    displayError() {
      this.sumbitReply = "error";
    },
    formatTimeForPrint(seconds) {
      // console.log(seconds)
      var s = moment.duration(seconds * 1000);
      // console.log(JSON.stringify(s._data));
      return (
        s._data.days +
        " days " +
        s._data.hours +
        " hours " +
        s._data.minutes +
        " minutes " +
        s._data.seconds +
        " seconds "
      );
    },
    niceMomentTime(seconds) {
      // console.log(seconds)
      var s = moment.duration(seconds * 1000);
      // console.log(JSON.stringify(s._data));
      return s._data.hours + ":" + s._data.minutes + ":" + s._data.seconds;
    },
    niceMomentDaysAndTime(seconds) {
      // console.log(seconds)
      var s = moment.duration(seconds * 1000);
      // console.log(JSON.stringify(s._data));
      return (
        s._data.days +
        ":" +
        s._data.hours +
        ":" +
        s._data.minutes +
        ":" +
        s._data.seconds
      );
    }
  },
  components: {
    AppBar,
    IntercomStatsManualData,
    IntercomPrintView
    // ReportServersEditor,
    // SupportSummaryEditor
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = !this.overlay;
          // this.hasLoaded = true;
          // this.viewMainSearchWindow = false;
        }, 3000);
    }
  }
};
</script>
